import 'whatwg-fetch';
import { handleErrors } from '../../components/Common';

export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN';
export const LOGOFF = 'LOGOFF';
export const RECEIVE_PUSH_NOTIFICATION_SUBSCRUPTION_ID =
  'RECEIVE_PUSH_NOTIFICATION_SUBSCRUPTION_ID';

export const logoff = () => ({
  type: LOGOFF
});

export const requestLogin = () => ({
  type: REQUEST_LOGIN
});

export const receiveLogin = ({ user = '', jwt = '' }) => ({
  type: RECEIVE_LOGIN,
  user,
  jwt
});

export const receivePushNotificationSubscriptionId = ({
  pushNotificationSubscriptionId
}) => ({
  type: RECEIVE_PUSH_NOTIFICATION_SUBSCRUPTION_ID,
  pushNotificationSubscriptionId
});

export const submitLogin = ({ identifier, password }) => dispatch => {
  dispatch(requestLogin());
  const url = `${process.env.REACT_APP_CMS_HOST}/auth/local`;
  return fetch(url, {
    headers: {
      // eslint-disable-next-line quote-props
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({
      identifier,
      password
    })
  })
    .then(handleErrors)
    .then(response => response.json())
    .then(json => dispatch(receiveLogin(json)))
    .catch(() => {
      dispatch(receiveLogin([]));
    });
};
