import { useEffect } from 'react';
import { connect } from 'react-redux';

const StoreManager = ({ persistor, logoffState }) => {
  useEffect(() => {
    if (logoffState) {
      persistor.pause();
      persistor.flush().then(() => {
        persistor.purge();
        window.location.replace('/');
      });
    }
  }, [logoffState]);
  return null;
};

const mapStateToProps = state => ({
  logoffState: state.auth.logoff
});

export default connect(
  mapStateToProps,
  null
)(StoreManager);
