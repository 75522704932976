const getParameterByName = (name, url) => {
  if (!url) return null;
  const param = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + param + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export default getParameterByName;
