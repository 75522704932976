import React, { useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { messaging, isSupported } from './initialize';

const FirebaseMessaging = () => {
  useEffect(() => {
    if (isSupported()) {
      messaging.onMessage(payload => {
        const {
          data: {
            method = 'info',
            toastrOptions = {},
          },
          notification: {
            title,
            body
          },
        } = payload;
        toastr[method](title, body, toastrOptions);
        // console.log('Message received. ', payload);
      });
    }
  }, []);
  return null;
};

export default FirebaseMessaging;
