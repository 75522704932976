import React from "react";
import Loadable from "react-loadable";
import { Loader } from "semantic-ui-react";

const Loading = props => {
  if (props.error) {
    return (
      <div>
        Error!
        {/* <button onClick={props.retry}>Retry</button> */}
      </div>
    );
  } else if (props.pastDelay) {
    return <Loader active />;
  }
  return null;
};

const withLoadable = opts => {
  return Loadable(
    Object.assign(
      {
        loading: Loading,
        delay: 200,
        timeout: 10000
      },
      opts
    )
  );
};

export default withLoadable;
