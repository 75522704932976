import { combineReducers } from 'redux';
import { RECEIVE_LOGIN, REQUEST_LOGIN, LOGOFF, RECEIVE_PUSH_NOTIFICATION_SUBSCRUPTION_ID } from './actions';

const user = (state = {}, { type, user, pushNotificationSubscriptionId = ''}) => {
  switch (type) {
    case RECEIVE_LOGIN:
      return {
        phoneNumber: '',
        pushNotificationSubscriptionId,
        ...user
      };
    case RECEIVE_PUSH_NOTIFICATION_SUBSCRUPTION_ID:
      return {
        ...state,
        pushNotificationSubscriptionId,
      };      
    default:
      return state;
  }
};

const jwt = (state = '', { type, jwt }) => {
  switch (type) {
    case RECEIVE_LOGIN:
      return jwt;
    default:
      return state;
  }
};

const isFetching = (state = 0, action) => {
  switch (action.type) {
    case REQUEST_LOGIN:
      return state + 1;
    case RECEIVE_LOGIN:
      return state - 1;
    default:
      return state;
  }
};

const logoff = (state = false, action) => {
  switch (action.type) {
    case LOGOFF:
      return true;
    default:
      return state;
  }
};

export const getUser = state => state.user;
export const getJwt = state => state.jwt;

export default combineReducers({
  user,
  jwt,
  isFetching,
  logoff
});
